/**
 * DO NOT MANUALLY EDIT THIS FILE
 * This file is generated automatically by the build script on prebuild step.
 * Please refer to documentation for more information
 */

export default function Settings({ children }) {
  return children({
    mainNavigation: [
      {
        label: 'Gemälde',
        href: '/de/kunst/gemaelde',
        columns: [
          {
            items: [
              {
                label: 'Blue-Chip-Gemälde',
                href: '/de/kunst/gemaelde?karrierephase=etablierte-kuenstler',
                obfuscate: true,
                icon: 'established',
                bold: true,
                className: '',
              },
              {
                label: 'Aufstrebende Gemälde',
                href: '/de/kunst/gemaelde?karrierephase=nachwuchskuenstler',
                obfuscate: true,
                icon: 'emerging',
                bold: true,
                className: '',
              },
              {
                label: 'Neue und Bemerkenswerte',
                href: '/de/kunst/gemaelde?karrierephase=neues',
                obfuscate: true,
                icon: 'new',
                bold: true,
                className: '',
              },
              {
                label: 'Alle Gemälde',
                href: '/de/kunst/gemaelde',
                bold: true,
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              { type: 'separator', className: '' },
              {
                label: 'Alle Gemälde',
                href: '/de/kunst/gemaelde',
                obfuscate: true,
                bold: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            items: [
              { label: 'Abstrakte Gemälde', href: '/de/kunst/gemaelde/abstrakt', className: '' },
              { label: 'Figürliche Gemälde', href: '/de/kunst/gemaelde/figurativ', className: '' },
              { label: 'Ölgemälde', href: '/de/kunst/gemaelde/oel', className: '' },
              { label: 'Acrylgemälde', href: '/de/kunst/gemaelde/acryl', className: '' },
              {
                label: 'Landschaftsgemälde',
                href: '/de/kunst/gemaelde/landschaften',
                className: '',
              },
              { label: 'Art-Deco-Gemälde', href: '/de/kunst/gemaelde/art-deco', className: '' },
              { label: 'Aktgemälde', href: '/de/kunst/gemaelde/akte', className: '' },
            ],
            className: '',
          },
          {
            items: [
              {
                type: 'box',
                label: 'Das Beste in Gemälden',
                text: 'Unübersehbare Gemälde von aufstrebenden Künstlern',
                href: '/de/sammlung/1421/beste-in-gemaelde',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/10/f1136826-5789-46f4-a405-b62da2b03100.jpg',
                className: '',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
      {
        label: 'Drucke',
        href: '/de/kunst/kunstdrucke',
        columns: [
          {
            items: [
              {
                label: 'Blue-Chip-Drucke',
                href: '/de/kunst/kunstdrucke?karrierephase=etablierte-kuenstler',
                obfuscate: true,
                icon: 'established',
                bold: true,
                className: '',
              },
              {
                label: 'Aufstrebende Drucke',
                href: '/de/kunst/kunstdrucke?karrierephase=nachwuchskuenstler',
                obfuscate: true,
                icon: 'emerging',
                bold: true,
                className: '',
              },
              {
                label: 'Neue und Bemerkenswerte',
                href: '/de/kunst/kunstdrucke?karrierephase=neues',
                obfuscate: true,
                icon: 'new',
                bold: true,
                className: '',
              },
              {
                label: 'Alle Kunstdrucke',
                href: '/de/kunst/kunstdrucke',
                bold: true,
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              { type: 'separator', className: '' },
              {
                label: 'Alle Kunstdrucke',
                href: '/de/kunst/kunstdrucke',
                obfuscate: true,
                bold: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            items: [
              {
                label: 'Street-Art-Drucke',
                href: '/de/kunst/kunstdrucke/street-art',
                className: '',
              },
              { label: 'Pop-Art-Drucke', href: '/de/kunst/kunstdrucke/pop-art', className: '' },
              {
                label: 'Landschaftsdrucke',
                href: '/de/kunst/kunstdrucke/landschaften',
                className: '',
              },
              {
                label: 'Botanische Drucke',
                href: '/de/kunst/kunstdrucke/botanisch',
                className: '',
              },
              { label: 'Tierdrucke', href: '/de/kunst/kunstdrucke/tiere', className: '' },
              { label: 'Siebdrucke', href: '/de/kunst/kunstdrucke/siebdruck', className: '' },
              { label: 'Linoldrucke', href: '/de/kunst/kunstdrucke/lino', className: '' },
              { label: 'Mono-Drucke', href: '/de/kunst/kunstdrucke/mono', className: '' },
            ],
            className: '',
          },
          {
            items: [
              {
                type: 'box',
                label: 'Beste in Druck',
                text: 'Hochwertige Drucke für anspruchsvolle Sammler',
                href: '/de/sammlung/1427/beste-druck',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/10/4d0aadcd-63e2-4bb7-a1f0-ec1bd9a54960.jpg',
                className: '',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
      {
        label: 'Fotografie',
        href: '/de/kunst/fotografie',
        columns: [
          {
            items: [
              {
                label: 'Blue-Chip-Fotografie',
                href: '/de/kunst/fotografie?karrierephase=etablierte-kuenstler',
                obfuscate: true,
                icon: 'established',
                bold: true,
                className: '',
              },
              {
                label: 'Aufstrebende Fotografie',
                href: '/de/kunst/fotografie?karrierephase=nachwuchskuenstler',
                obfuscate: true,
                icon: 'emerging',
                bold: true,
                className: '',
              },
              {
                label: 'Neue und Bemerkenswerte',
                href: '/de/kunst/fotografie?karrierephase=neues',
                obfuscate: true,
                icon: 'new',
                bold: true,
                className: '',
              },
              {
                label: 'Alle Fotografien',
                href: '/de/kunst/fotografie',
                bold: true,
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              { type: 'separator', className: '' },
              {
                label: 'Alle Fotografien',
                href: '/de/kunst/fotografie',
                obfuscate: true,
                bold: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            items: [
              {
                label: 'Abstrakte Fotografie',
                href: '/de/kunst/fotografie/abstrakt',
                className: '',
              },
              {
                label: 'Landschaftsfotografie',
                href: '/de/kunst/fotografie/landschaften',
                className: '',
              },
              { label: 'Aktfotografie', href: '/de/kunst/fotografie/akte', className: '' },
              {
                label: 'Schwarz-Weiß-Fotografie',
                href: '/de/kunst/fotografie/schwarz-weiss',
                className: '',
              },
              { label: 'Digitale Fotografie', href: '/de/kunst/fotografie/digital', className: '' },
            ],
            className: '',
          },
          {
            items: [
              {
                type: 'box',
                label: 'Das Beste in der Fotografie',
                text: 'Objektiv gefertigte Exzellenz für jeden Geschmack',
                href: '/de/sammlung/1151/beste-fotografie',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/10/4e016d65-8de4-4c70-9530-a91a81bb16f9.jpg',
                className: '',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
      {
        label: 'Skulpturen',
        href: '/de/kunst/skulpturen',
        columns: [
          {
            items: [
              {
                label: 'Blue-Chip-Sculpturen',
                href: '/de/kunst/skulpturen?karrierephase=etablierte-kuenstler',
                obfuscate: true,
                icon: 'established',
                bold: true,
                className: '',
              },
              {
                label: 'Aufstrebende Skulpturen',
                href: '/de/kunst/skulpturen?karrierephase=nachwuchskuenstler',
                obfuscate: true,
                icon: 'emerging',
                bold: true,
                className: '',
              },
              {
                label: 'Neue und Bemerkenswerte',
                href: '/de/kunst/skulpturen?karrierephase=neues',
                obfuscate: true,
                icon: 'new',
                bold: true,
                className: '',
              },
              {
                label: 'Alle Skulpturen',
                href: '/de/kunst/skulpturen',
                bold: true,
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              { type: 'separator', className: '' },
              {
                label: 'Alle Skulpturen',
                href: '/de/kunst/skulpturen',
                obfuscate: true,
                bold: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            items: [
              {
                label: 'Abstrakte Skulpturen',
                href: '/de/kunst/skulpturen/abstrakt',
                className: '',
              },
              {
                label: 'Figürliche Skulpturen',
                href: '/de/kunst/skulpturen/figurativ',
                className: '',
              },
              { label: 'Aktskulpturen', href: '/de/kunst/skulpturen/akte', className: '' },
              { label: 'Metallskulpturen', href: '/de/kunst/skulpturen/metall', className: '' },
              { label: 'Bronzeskulpturen', href: '/de/kunst/skulpturen/bronze', className: '' },
              { label: 'Holzskulpturen', href: '/de/kunst/skulpturen/holz', className: '' },
              { label: 'Keramikskulpturen', href: '/de/kunst/skulpturen/keramik', className: '' },
            ],
            className: '',
          },
          {
            items: [
              {
                type: 'box',
                label: 'Beste in der Skulptur',
                text: 'Hervorragende Skulpturen für jeden Raum geeignet.',
                href: '/de/sammlung/1429/beste-skulpturen',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/10/0f3bacf7-e68e-4289-80bf-0757f0b1160f.jpg',
                className: '',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
      {
        label: 'Zeichnungen',
        href: '/de/kunst/zeichnungen',
        columns: [
          {
            items: [
              {
                label: 'Blue-Chip-Zeichnungen',
                href: '/de/kunst/zeichnungen?karrierephase=etablierte-kuenstler',
                obfuscate: true,
                icon: 'established',
                bold: true,
                className: '',
              },
              {
                label: 'Aufstrebende Zeichnungen',
                href: '/de/kunst/zeichnungen?karrierephase=nachwuchskuenstler',
                obfuscate: true,
                icon: 'emerging',
                bold: true,
                className: '',
              },
              {
                label: 'Neue und Bemerkenswerte',
                href: '/de/kunst/zeichnungen?karrierephase=neues',
                obfuscate: true,
                icon: 'new',
                bold: true,
                className: '',
              },
              {
                label: 'Alle Zeichnungen',
                href: '/de/kunst/zeichnungen',
                bold: true,
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              { type: 'separator', className: '' },
              {
                label: 'Alle Zeichnungen',
                href: '/de/kunst/zeichnungen',
                obfuscate: true,
                bold: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            items: [
              {
                label: 'Realistische Zeichnungen',
                href: '/de/kunst/zeichnungen/realistisch',
                className: '',
              },
              {
                label: 'Abstrakte Zeichnungen',
                href: '/de/kunst/zeichnungen/abstrakt',
                className: '',
              },
              {
                label: 'Anschauliche Zeichnungen',
                href: '/de/kunst/zeichnungen/illustrativ',
                className: '',
              },
              { label: 'Aktzeichnungen', href: '/de/kunst/zeichnungen/akte', className: '' },
              {
                label: 'Porträtzeichnungen',
                href: '/de/kunst/zeichnungen/portraits',
                className: '',
              },
              {
                label: 'Bleistiftzeichnungen',
                href: '/de/kunst/zeichnungen/bleistift',
                className: '',
              },
              { label: 'Kohlezeichnunge', href: '/de/kunst/zeichnungen/holzkohle', className: '' },
              { label: 'Tintenzeichnungen', href: '/de/kunst/zeichnungen/tinte', className: '' },
            ],
            className: '',
          },
          {
            items: [
              {
                type: 'box',
                label: 'Beste in Zeichnungen',
                text: 'Zeichnungen auf Galerie-Niveau von unübertroffenem Talent',
                href: '/de/sammlung/1428/beste-zeichnungen',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/10/ea14d062-5e79-4d6e-b3ad-5adf548d91ad.jpg',
                className: '',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
      {
        label: 'Künstler',
        href: '/de/kuenstler',
        columns: [
          {
            items: [
              {
                label: 'Alle Künstler',
                href: '/de/kuenstler',
                bold: true,
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              { label: 'Maler', href: '/de/kuenstler/maler', bold: true, className: '' },
              { label: 'Grafiker', href: '/de/kuenstler/grafiker', bold: true, className: '' },
              { label: 'Fotografen', href: '/de/kuenstler/fotografen', bold: true, className: '' },
              { label: 'Bildhauer', href: '/de/kuenstler/bildhauer', bold: true, className: '' },
              { label: 'Zeichner', href: '/de/kuenstler/verfasser', bold: true, className: '' },
              { label: 'Kollagist', href: '/de/kuenstler/kollagist', bold: true, className: '' },
              { type: 'separator', className: '' },
              {
                label: 'Alle Künstler',
                href: '/de/kuenstler',
                obfuscate: true,
                bold: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Curatorial Process',
                href: 'https://get.riseart.com/de/kuratieren',
                obfuscate: true,
                external: true,
                bold: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            label: 'Karrierephase',
            items: [
              {
                label: 'Etablierte Künstler',
                href: '/de/kuenstler?karrierephase=etablierte-kuenstler',
                obfuscate: true,
                icon: 'established',
                bold: true,
                className: '',
              },
              {
                label: 'Aufstrebende Künstler',
                href: '/de/kuenstler?karrierephase=nachwuchskuenstler',
                obfuscate: true,
                icon: 'emerging',
                bold: true,
                className: '',
              },
              {
                label: 'Neu & Interessant',
                href: '/de/kuenstler?karrierephase=neues',
                obfuscate: true,
                icon: 'new',
                bold: true,
                className: '',
              },
              { type: 'separator', className: '' },
              {
                label: 'Zu beobachtender künstler',
                href: '/de/sammlung/1415/zu_beobachtender_kunstler',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Zeitlose Meister',
                href: '/de/sammlung/1672/werke-zeitloser-meister',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            label: 'Ausgewählte Künstler',
            href: '/de/kuenstler/bildhauer',
            obfuscate: true,
            items: [
              {
                label: 'Daniela Schweinsberg',
                href: '/de/kuenstler/49971/daniela-schweinsberg',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/e556616e-64f8-486e-ba44-dfc1a893e39f.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Katrin Roth',
                href: '/de/kuenstler/120150/katrin-roth',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/cb79599c-a88d-4c76-ba9a-41b37096c34c.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Mazen Khadda',
                href: '/de/kuenstler/64912/mazen-khaddaj',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/6987cb2d-2118-4761-bccf-3c63862f75d2.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Anita Kaufmann',
                href: '/de/kuenstler/126312/anita-kaufmann',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/22967aca-81d6-49f3-99c5-2d9e68c30a3e.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Sara Hoppe',
                href: '/de/kuenstler/121525/sara-hoppe',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/fd0b1c12-34de-4404-aec4-d9eb93415fba.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Bea Garding Schubert',
                href: '/de/kuenstler/59607/garding-schubert',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/6c18bf8a-0d5b-4489-8d7e-4996092064da.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            label: '',
            items: [
              {
                label: 'Fintan Whelan',
                href: '/de/kuenstler/30179/fintan-whelan',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/886fd27f-3c42-49fb-95e7-efe384a202f2.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Susanne Kirsch',
                href: '/de/kuenstler/118973/susanne-kirsch',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/c44f7193-44fe-4968-bdb4-7236f7980996.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Karoline Kroiss',
                href: '/de/kuenstler/126428/karoline-kroiss',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/c6c92f2c-0933-4461-b2d0-8e55ff6585d9.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Tanja Vetter',
                href: '/de/kuenstler/40071/tanja-vetter',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/762b3fa1-2555-4fde-91a3-f1cda978fe6d.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Hugo Nefe',
                href: '/de/kuenstler/131364/hugo-nefe',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/2553b1cf-ce69-4c99-8079-c0231c4987f3.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Gyunay Aliev',
                href: '/de/kuenstler/130511/gyunay-aliev',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/eecfd453-72cc-404e-afe1-c632aeda8d3f.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
      {
        label: 'Inspiration',
        href: '/de/artikel',
        columns: [
          {
            label: 'Editorial',
            href: '/de/artikel',
            obfuscate: true,
            items: [
              {
                label: 'Editorial',
                href: '/de/artikel',
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              {
                label: 'Interviews Mit Künstlern',
                href: '/de/artikel/interviews-mit-kuenstlern',
                className: '',
              },
              {
                label: 'Kunstnews',
                href: '/de/artikel/nachrichten-aus-der-kunstszene',
                className: '',
              },
              { label: 'Kommende Veranstaltungen', href: '/de/veranstaltungen', className: '' },
              { type: 'separator', className: '' },
              {
                label: 'Kunstkatalog',
                href: 'https://get.riseart.com/de/kataloge/',
                obfuscate: true,
                external: true,
                className: '',
              },
              { label: 'Kunstleitfäden', href: '/de/leitfaeden', className: '' },
              { label: 'Nachrichtenfeeds', href: '/de/aktivitaet', className: '' },
            ],
            className: '',
          },
          {
            label: 'Sammlungen',
            href: '/de/sammlungen',
            items: [
              {
                label: 'Kunstsammlungen',
                href: '/de/sammlungen',
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              {
                label: 'Auswahl der Kuratoren',
                href: '/de/sammlung/1313/auswahl-des-kurators',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Große Meister der Kunst',
                href: '/de/sammlung/1672/werke-zeitloser-meister',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Wöchentliche Auswahl',
                href: '/de/sammlung/1402/woechentliche-auswahl',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Sammler-Wunschliste',
                href: '/de/sammlung/222/aktuelle-kunsttrends-weltweite-favoriten',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              { type: 'separator', className: '' },
              {
                label: 'Aufstrebender Künstler',
                href: '/de/kunst?karrierephase=nachwuchskuenstler',
                obfuscate: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Kunstwerke im Großformat',
                href: '/de/kunst?groesse=L-XXL',
                obfuscate: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Bezahlbare Kunstauswahl',
                href: '/de/kunst?groesse=S-XXL&preis=0-1500',
                obfuscate: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            label: 'Dienstleistungen',
            items: [
              {
                label: 'Kunst-Persönlichkeitstest',
                href: '/de/test/anfang',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Persönliche Kunstberatung',
                href: '/de/kunstberatung',
                obfuscate: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Individuelle Auftragsarbeiten',
                href: 'https://get.riseart.com/de/individuelle-auftragsarbeiten/',
                obfuscate: true,
                external: true,
                className: '',
              },
              {
                label: 'Angebot Abgeben',
                href: '/de/post/2731/machen-sie-ein-angebot',
                obfuscate: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              { type: 'separator', className: '' },
              {
                label: 'Geschenkgutschein',
                href: '/de/geschenkkarten',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            items: [
              {
                type: 'box',
                label: 'Kunstkatalog',
                text: 'Originale Kunst von aufstrebenden Künstlern',
                href: 'https://get.riseart.com/de/kataloge/',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/01/3c3e9a64-e502-42cf-8036-562527a6548d.jpg',
                external: true,
                obfuscate: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
      {
        label: 'Kunstberatung',
        href: '/de/kunstberatung',
        obfuscate: true,
        columns: [
          {
            label: 'Kunstberatung',
            href: '/de/kunstberatung',
            obfuscate: true,
            items: [
              {
                label: 'Kuration',
                href: 'https://get.riseart.com/de/kuratieren',
                obfuscate: true,
                external: true,
                className: '',
              },
              { label: 'Kunstberatungsservice', href: '/de/kunstberatung', className: '' },
              { label: 'Kunstvermietung', href: '/de/uber/vermietung', className: '' },
              {
                label: 'Kostenlose Rücksendung',
                href: 'https://get.riseart.com/ruecksendungen/',
                obfuscate: true,
                external: true,
                className: '',
              },
              { type: 'separator', className: '' },
              {
                label: 'Kunstberater kontaktieren',
                href: '/de/kunstberatung#anfrageformular',
                obfuscate: true,
                external: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            label: 'Arbeitsplatz- und Handelsberatung',
            items: [
              { label: 'Arbeitsplatzlösungen', href: '/de/kunst-fuers-buero', className: '' },
              {
                label: 'Designer und Gastfreundschaft',
                href: '/de/kunst-fuer-innenarchitekten',
                className: '',
              },
              { type: 'separator', className: '' },
              {
                label: 'Kontaktiere unser Team',
                href: '/de/kunstberatung#anfrageformular',
                obfuscate: true,
                external: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            items: [
              {
                type: 'box',
                label: 'Soho Home x Rise Art',
                text: 'Rekreieren Sie das Gefühl der Soho Home Studios mit unseren sorgfältig ausgewählten Kollektionen',
                href: '/sohohome',
                obfuscate: true,
                external: true,
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/10/4b8a26a1-78a1-44f9-8c86-0aed75a552bb.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
    ],
    footerLinks: [
      {
        name: 'FÜR KÜNSTLER',
        links: [
          {
            label: 'Bieten Sie Ihre Kunst an',
            href: '/art/submit',
            obfuscate: true,
            external: true,
          },
          {
            label: 'Verkäuferkonsole',
            href: 'https://sellers.riseart.com',
            obfuscate: true,
            external: true,
          },
        ],
      },
      {
        name: 'VOOR VERZAMELAARS',
        links: [
          {
            label: 'Kundenbewertungen',
            href: 'https://www.reviews.io/company-reviews/store/riseart-com',
            obfuscate: true,
            external: true,
          },
          {
            label: 'Wie wir kuratieren',
            href: 'https://get.riseart.com/de/kuratieren/',
            obfuscate: true,
            external: true,
          },
          {
            label: 'Rücksendungen',
            href: 'https://get.riseart.com/ruecksendungen/',
            obfuscate: true,
            external: true,
          },
          {
            label: 'Rahmung ihrer kunstwerke',
            href: 'https://get.riseart.com/de/einrahmung/',
            obfuscate: true,
            external: true,
          },
          {
            label: 'FAQ van klanten',
            href: 'https://beistand.riseart.com/',
            obfuscate: true,
            external: true,
          },
        ],
      },
      {
        name: 'ÜBER UNS',
        links: [
          { label: 'Über uns', href: '/de/ueber-uns', obfuscate: true, external: false },
          { label: 'Kontaktiere uns', href: '/de/kontakt', obfuscate: true, external: false },
          { label: 'Geschenkkarten', href: '/de/geschenkkarten', obfuscate: true, external: false },
          { label: 'Rechtliches', href: '/de/rechtliches', obfuscate: true, external: false },
        ],
      },
    ],
  });
}
